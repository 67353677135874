<template>
  <div class="container-fluid">
    <PageTitle :no-add="true" />
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-2 mb-3">
            <router-link :to="{ name: 'MrPustakaRisiko' }">
              <b-button class="mr-3" v-tooltip="'Kembali'">
                <i class="ti-arrow-left"></i>
              </b-button>
            </router-link>
          </div>
            <div class="col-md-12">
              <div class="wrap_info_preview">
                <div class="table-responsive">
                    <table class="table product-overview no-border">
                    <tbody>
                        <tr>
                        <td>
                            <div class="wrap_preview_prof">
                            <h3>Perspektif</h3>
                            <p>{{risiko.mp_description}}</p>
                            </div>
                        </td>
                        <td>
                            <div class="wrap_preview_prof">
                            <h3>Unit Kerja</h3>
                            <p>{{risiko.muk_description}}</p>
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td>
                            <div class="wrap_preview_prof">
                            <h3>Kategori</h3>
                            <p>{{risiko.mcr_description}}</p>
                            </div>
                        </td>
                        <td>
                            <div class="wrap_preview_prof">
                                <h3>Sasaran RTU</h3>
                                <p>{{risiko.msr_description}}</p>
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <td>
                            <div class="wrap_preview_prof">
                            <h3>Nama Risiko</h3>
                            <p>{{risiko.mr_name}}</p>
                            </div>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs bg-white mt-3 customtab" role="tablist">
      <li class="nav-item"> <router-link :class="['nav-link', $route.meta.risiko == 'sebab' ? 'active' : '']" :to="{name: 'MrRisikoSebab', params: {risikoSlug: $route.params.risikoSlug}}"><span class="hidden-sm-up"></span> <span class="hidden-xs-down">Pustaka Risiko Sebab</span></router-link> </li>
      <li class="nav-item"> <router-link :class="['nav-link', $route.meta.risiko == 'dampak' ? 'active' : '']" :to="{name: 'MrRisikoDampak', params: {risikoSlug: $route.params.risikoSlug}}"><span class="hidden-sm-up"></span> <span class="hidden-xs-down">Pustaka Risiko Dampak</span></router-link> </li>
      <li class="nav-item"> <router-link :class="['nav-link', $route.meta.risiko == 'kendali' ? 'active' : '']" :to="{name: 'MrRisikoKendali', params: {risikoSlug: $route.params.risikoSlug}}"><span class="hidden-sm-up"></span> <span class="hidden-xs-down">Pustaka Risiko Pengendalian</span></router-link> </li>
    </ul>
    <template v-if="isList">
      <card-list 
        :filter.sync="filter"
        :dataListing.sync="data"
        :idKey="idKey"
        :fields="fields"
        
        :statusOptions="status"
        :queryRoute=" {risiko_id:$route.params.risikoSlug}"
        @getRisiko="getRisiko"
      > 
        <template #btnleft>
          <router-link
            :to="{name:$route.name, params:{pageSlug:'add'}}" 
            class="btn ml-3 btn-info">
            <i class="fa fa-plus-circle"></i> Tambah Data
          </router-link> 
        </template>
        <template #filters>
          <b-col md=4>
            <VSelect 
              v-model="filter.kategori" 
              placeholder="-- Semua Kategori --"
              :options="mrKategori" 
              :reduce="v=>String(v.value)" 
              @input="doFilter"
            />
          </b-col>
        </template>
        <template #mrd_is_active="data">
          <LabelStatus :status="data.scope.item[statusKey]" />
        </template>
        <template #aksi="data">
          <div class="btn-toolbar">
            <b-dropdown no-flip>
              <template #button-content>
                <i class="icon-menu"></i>
              </template>
              <b-dropdown-item :to="{name:$route.name, params:{pageSlug:data.scope.item[idKey]}}">
                Ubah Data
              </b-dropdown-item>
              <b-dropdown-item @click="doDelete(data.scope.index, data.scope.item, {query: {risiko_id:$route.params.risikoSlug}})">Hapus</b-dropdown-item>
            </b-dropdown>
            <b-dropdown 
              no-flip 
              variant="info" 
              class="ml10" toggle-class="absolute-button"
            >
              <template #button-content>
                <i class="ti-exchange-vertical" v-b-tooltip.hover 
              title="Ganti Status"></i>
              </template>
              <b-dropdown-item 
                :class="{markedDropdown:data.scope.item[statusKey]==1}"
                @click="doChangeStatus(data.scope.index, data.scope.item, 1)"
              >
                Active
              </b-dropdown-item>
              <b-dropdown-item 
                :class="{markedDropdown:data.scope.item[statusKey]==2}"
                @click="doChangeStatus(data.scope.index, data.scope.item, 2)"
              >
                Inactive
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </card-list>
    </template>
    <div 
      v-else 
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd?'Tambah':'Ubah'}} Data</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm2">
          <form @submit.prevent="handleSubmit(doSubmitCRUD)">
            <b-row class="mb-2">
              <b-col md=6>
                <b-form-group>
                  <template #label>
                    Kategori <span class="text-danger mr5">*</span>
                  </template>
                  <VSelect 
                    v-model="row.mrd_category" 
                    placeholder="-- Pilih Satu --"
                    :options="mrKategori" 
                    :reduce="v=>v.value" 
                  />
                  <VValidate 
                    name="Kategori Risiko" 
                    v-model="row.mrd_category" 
                    :rules="mrValidation.mrd_category" 
                  />
                </b-form-group>
              </b-col>
               <b-col md=6>
                <b-form-group>
                  <template #label>
                    Deskripsi <span class="text-danger mr5">*</span>
                  </template>
                  <b-input
                    type="text"
                    v-model="row.mrd_name"
                  />
                  <VValidate 
                    name="Deskripsi" 
                    v-model="row.mrd_name" 
                    :rules="'required'" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md=6
              >
                <b-form-group>
                  <template #label>
                    Status <span class="text-danger mr5">*</span>
                  </template>
                  <b-form-radio-group
                    id="radio-group-2"
                    v-model="row[statusKey]"
                    name="radio-group-2"
                  >
                    <b-form-radio value=1>Active</b-form-radio>
                    <b-form-radio value=2>Inactive</b-form-radio>
                  </b-form-radio-group>
                  <VValidate 
                    name="Status" 
                    v-model="row[statusKey]" 
                    :rules="mrValidation[statusKey]" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div class="row mt-4">
              <div class="col-md-2 offset-md-10">
                <button type="submit" class="btn btn-block btn-rounded  btn-info">Submit</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>
  </div>
</template>
<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CardList from '@/components/CardList'
import Gen from '@libs/Gen.js'

let $ = global.jQuery;
let _ = global._


export default {
   extends:GlobalVue
   ,
   components: {
      PageTitle,
      CardList
   },
   data(){
       return {
          idKey:'mrd_id',
            statusKey:'mrd_is_active',
            fields:[
                { key: "select", label: "" },
                { key:'number', label:'#' },
                { key:'mcr_description', label:'Kategori' },
                { key:'mrd_name', label:'Deskripsi' },
                { key:'mrd_is_active', label:'Status', is_custom:true },
                { key:'aksi', is_custom:true },
            ],
            mrPerspektif:[],
            mrUnitKerja: [],
            mrValidation: [],
            mrKategori: [],
            status: [],
            row: {},
            risiko: [],
       }
   },
   mounted(){
    this.apiGet({query: {risiko_id:this.$route.params.risikoSlug}})
    this.getRisiko()
  },
  watch:{
    $route(){
      this.apiGet({query: {risiko_id:this.$route.params.risikoSlug}})
      this.getRisiko()
    }
  },
  methods: {
    getRisiko(){
        Gen.apiRest(
        "/get/"+this.modulePage,
        {
          params: {
            detailRisiko: this.$route.params.risikoSlug
          }
        }
      ).then(res=>{
          this.risiko = res.data.risiko
        })
        .catch(err => console.log(err))
    },
    doSubmit(action, input, callback=false, method="POST", IDFORM='VForm2'){
      this.$refs[IDFORM].validate().then(success => {
        if (!success) { return; }
        
        if(this.loadingOverlay) return
        this.loadingOverlay = true 

        var data = $.parseJSON(JSON.stringify(input));
        var btnSubmit = $(".btn-loading");
        var prevHtml = btnSubmit.html();

        if($(".infoHTML").length){
          $(".infoHTML").removeClass('alert alert-danger alert-warning');
          $(".infoHTML").html('');
        }

        btnSubmit.html();
        Gen.apiRest(action, {data: $.extend({params:this.params}, data)}, method).then((res)=>{
            this.loadingOverlay = false
            let resp = res.data

            btnSubmit.html(prevHtml);

            resp.statusType = 200;
            this.doSetAlertForm(resp);
            
            if(callback){
              callback('success', resp)
            }

            setTimeout(()=>{ this.message="" },15000);
        }).catch((err)=>{
            this.loadingOverlay = false
            btnSubmit.html(prevHtml);
            
            if(err){
                err.statusType = err.status;
                err.status = "error";
                err.message = err.response?.data?.message;
                err.messageError = err.message
            }

            this.doSetAlertForm(err);
            if(this.failReset)
                this.$nextTick(() => {
                    this.$refs.form.reset();
                    this.message=""
                });
                
            if(callback){
              callback('error', err)
            }
        })
      });
    },
    doSubmitCRUD(IDForm='VForm2'){
    return this.doSubmit(
        "/do/"+this.modulePage, 
        _.assign({type:this.isAdd?'add':'update'}, {id: (this.pageId||this.pageSlug), mrd_risiko_id: this.$route.params.risikoSlug}, _.clone(this.row)), 
        (type, resp)=>{
            if(type=='success'){
            this.$router.push({name:this.$route.name})
            }else{
            if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data),(i,v)=>{
                    msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v]);
                })
                return global.Swal.fire("Blocked With Validation!", msg)
                }else{
                return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
            }else if(resp.response.status==400){
                return global.Swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
                })
            }
            }
        },
        'POST', IDForm
    )
    },
  }
    
}
</script>